.date-picker-container-dark .react-datepicker-month {
    border-radius: 15px !important;
    z-index: 1;
    box-shadow: 0 0 0 1px #fff !important;
    --shadow-color: #fff !important;
    border-color: #fff !important;
}

.date-picker-container-dark {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 0.8rem;
    background-color: #18181b;
    color: #fff !important;
    border: 1px solid #27272a;
    border-radius: 15px;
    display: inline-block;
    position: relative;
    line-height: initial;
}

.date-picker-container-dark .react-datepicker__day--disabled,
.date-picker-container-dark .react-datepicker__month-text--disabled,
.date-picker-container-dark .react-datepicker__quarter-text--disabled,
.date-picker-container-dark .react-datepicker__year-text--disabled {
    cursor: default;
    color: #a1a1aa !important;
}

.date-picker-container-dark .react-datepicker__day--disabled,
.date-picker-container-dark .react-datepicker__month-text--disabled,
.date-picker-container-dark .react-datepicker__quarter-text--disabled,
.date-picker-container-dark .react-datepicker__year-text--disabled {
    cursor: default;
    color: #a1a1aa !important;
}

.date-picker-container-dark .react-datepicker__day--holidays,
.date-picker-container-dark .react-datepicker__month-text--holidays,
.date-picker-container-dark .react-datepicker__quarter-text--holidays,
.date-picker-container-dark .react-datepicker__year-text--holidays {
    position: relative;
    border-radius: 0.3rem;
    background-color: rgb(59, 17, 6);
    color: #fff;
}

.date-picker-container-dark .react-datepicker__day-name,
.date-picker-container-dark .react-datepicker__day, .react-datepicker__time-name {
    color: #fff;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
}

.date-picker-container-dark .react-datepicker__year-option,
.date-picker-container-dark .react-datepicker__month-option,
.date-picker-container-dark .react-datepicker__month-year-option {
    line-height: 20px;
    width: 100%;
    border-radius: 15px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.date-picker-container-dark .react-datepicker__year-dropdown,
.date-picker-container-dark .react-datepicker__month-dropdown,
.date-picker-container-dark .react-datepicker__month-year-dropdown {
    background-color: #18181b;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    padding: 15px 6px 15px 15px;
    border-radius: 15px;
    z-index: 1;
    color: #fff;
    text-align: center;
    border: 1px solid #27272a;
}

.date-picker-container-dark .react-datepicker__today-button {
    background: #18181b;
    border-top: 1px solid #27272a;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    clear: left;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.date-picker-container-dark .react-datepicker__today-button:hover {
    background: #27272a;
}

.date-picker-container-dark .react-datepicker__day:not([aria-disabled=true]):hover,
.date-picker-container-dark .react-datepicker__month-text:not([aria-disabled=true]):hover,
.date-picker-container-dark .react-datepicker__quarter-text:not([aria-disabled=true]):hover,
.date-picker-container-dark .react-datepicker__year-text:not([aria-disabled=true]):hover {
    border-radius: 5px;
    background-color: #27272a;
}

.date-picker-container-dark .react-datepicker__year-dropdown:hover,
.date-picker-container-dark .react-datepicker__month-dropdown:hover,
.date-picker-container-dark .react-datepicker__month-year-dropdown:hover,
.date-picker-container-dark .react-datepicker__year-read-view:hover,
.date-picker-container-dark .react-datepicker__month-read-view:hover,
.date-picker-container-dark .react-datepicker__month-year-read-view:hover,
.date-picker-container-dark .react-datepicker__year-option:hover,
.date-picker-container-dark .react-datepicker__month-option:hover,
.date-picker-container-dark .react-datepicker__month-year-option:hover {
    cursor: pointer;
    
}

.date-picker-container-dark .react-datepicker__year-option:hover,
.date-picker-container-dark .react-datepicker__month-option:hover,
.date-picker-container-dark .react-datepicker__month-year-option:hover {
    background-color: #27272a;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    border-radius: 0.3rem;
    background-color: rgba(18, 74, 40,0.3);
    color: rgb(0, 0, 0);
}


.date-picker-container-dark .react-datepicker__day--selected,
.date-picker-container-dark .react-datepicker__day--in-selecting-range,
.date-picker-container-dark .react-datepicker__day--in-range,
.date-picker-container-dark .react-datepicker__month-text--selected,
.date-picker-container-dark .react-datepicker__month-text--in-selecting-range,
.date-picker-container-dark .react-datepicker__month-text--in-range,
.date-picker-container-dark .react-datepicker__quarter-text--selected,
.date-picker-container-dark .react-datepicker__quarter-text--in-selecting-range,
.date-picker-container-dark .react-datepicker__quarter-text--in-range,
.date-picker-container-dark .react-datepicker__year-text--selected,
.date-picker-container-dark .react-datepicker__year-text--in-selecting-range,
.date-picker-container-dark .react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: rgb(18, 74, 40);
    color: #fff;
}

.date-picker-container-dark .react-datepicker__month-container .react-datepicker__header {
    border-radius: 15px !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    background-color: transparent !important;
    border: none !important;
    border-bottom: 1px solid #27272a !important;
}

.date-picker-container-dark .react-datepicker__day-names .react-datepicker__day-name {
    color: #fff !important;
}

.date-picker-container-dark .react-datepicker__current-month {
    color: #fff !important;
}