.date-picker-container-light {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 0.8rem;
    border: 1px solid rgb(228, 228, 231);
    background-color: #fff;
    border-radius: 15px;
    display: inline-block;
    position: relative;
    line-height: initial;
}

.date-picker-container-light .react-datepicker__navigation {
    border-radius: 50% !important;
    background-color: transparent;
}

.date-picker-container-light .react-datepicker__header {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: #fff;
}

.date-picker-container-light .react-datepicker__day:not([aria-disabled=true]):hover,
.react-datepicker__month-text:not([aria-disabled=true]):hover,
.react-datepicker__quarter-text:not([aria-disabled=true]):hover,
.react-datepicker__year-text:not([aria-disabled=true]):hover {
    border-radius: 5px;
    background-color: rgb(228, 228, 231);
}

.date-picker-container-light .react-datepicker__day--selected,
.date-picker-container-light .react-datepicker__day--in-selecting-range,
.date-picker-container-light .react-datepicker__day--in-range,
.date-picker-container-light .react-datepicker__month-text--selected,
.date-picker-container-light .react-datepicker__month-text--in-selecting-range,
.date-picker-container-light .react-datepicker__month-text--in-range,
.date-picker-container-light .react-datepicker__quarter-text--selected,
.date-picker-container-light .react-datepicker__quarter-text--in-selecting-range,
.date-picker-container-light .react-datepicker__quarter-text--in-range,
.date-picker-container-light .react-datepicker__year-text--selected,
.date-picker-container-light .react-datepicker__year-text--in-selecting-range,
.date-picker-container-light .react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: rgb(22, 163, 74);
    color: #fff;
}

.date-picker-container-light .react-datepicker__today-button {
    background: #fff;
    border-top: 1px solid rgb(228, 228, 231);
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 5px 0;
    clear: left;
}

.date-picker-container-light .react-datepicker__today-button:hover {
    background: rgb(228, 228, 231);
}

.date-picker-container-light .react-datepicker__year-dropdown,
.date-picker-container-light .react-datepicker__month-dropdown,
.date-picker-container-light .react-datepicker__month-year-dropdown {
    background-color: #fff;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    padding: 15px 6px 15px 15px;
    border-radius: 15px;
    z-index: 1;
    color: #000;
    text-align: center;
    border: 1px solid rgb(228, 228, 231);
}

.date-picker-container-light .react-datepicker__day:not([aria-disabled=true]):hover,
.date-picker-container-light .react-datepicker__month-text:not([aria-disabled=true]):hover,
.date-picker-container-light .react-datepicker__quarter-text:not([aria-disabled=true]):hover,
.date-picker-container-light .react-datepicker__year-text:not([aria-disabled=true]):hover {
    border-radius: 5px;
    background-color: rgb(228, 228, 231);
}

.date-picker-container-light .react-datepicker__year-option:hover,
.date-picker-container-light .react-datepicker__month-option:hover,
.date-picker-container-light .react-datepicker__month-year-option:hover {
    background-color: rgb(228, 228, 231);
    border-radius: 15px;
}